/* auth page header */

.auth-page-header {
    &__title {
        font-size: 30px;
        font-weight: 700;

        margin-bottom: 5px;

        color: $black--two;
    }

    &__text {
        color: $grey--four;
    }
}

/* auth page body */

.auth-page-body {
    .auth-form {
        padding: 40px 20px;

        background-color: $white;
        &__single-field {
            label {
                font-size: 14px;

                margin-bottom: 20px;

                color: $black--two;
            }
            input,
            textarea {
                font-size: 18px;

                display: block;

                width: 100%;
                padding-bottom: 10px;

                color: $black--two;
                border: none;
                border-bottom: 2px solid $grey--eight;

                &:focus {
                    border-bottom: 2px solid $theme-color--default;
                }
            }

            ::placeholder {
                opacity: 1; /* Firefox */

/* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey--four;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $grey--four;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $grey--four;
            }
        }

        &__button {
            font-size: 16px;
            line-height: 1;

            width: 100%;
            padding: 15px 0;

            color: $white;
            border: none;
            background: none;
            background-color: $theme-color--default;
        }

        &__info-text {
            color: $grey--four;
            a {
                color: $theme-color--default;
            }
        }
    }
}

/* auth page footer */

.auth-page-separator {
    font-size: 18px;
    line-height: 1;

    display: block;

    color: $black--two;
}

.auth-page-social-login {
    button {
        line-height: 1;

        position: relative;

        display: block;

        width: 100%;
        margin-bottom: 20px;
        padding: 15px 0;

        color: $black;
        border: 1px solid $grey--seventeen;
        border-radius: 5px;
        background-color: transparent;
        &:last-child {
            margin-bottom: 0;
        }

        svg,
        img {
            position: absolute;
            top: 50%;
            left: 30px;

            transform: translateY(-50%);
        }
    }
}
