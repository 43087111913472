/*=============================================
=            No items found            =
=============================================*/

.no-items-found {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin-top: 200px;

    text-align: center;
    &__image {
        width: 100px;
        height: 100px;
        margin-right: auto;
        margin-bottom: 20px;
        margin-left: auto;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        p {
            a {
                font-weight: 500;

                display: block;

                text-decoration: underline;
            }
        }
    }
}

/*=====  End of No items found  ======*/
